#App {
  display: grid;
  min-height: 100vh;
  margin-left: 10px;
  margin-right: 10px;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto;
  font-size: 24px;
}

a {
  all: unset;
}

.menu-item {
  display: inline;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

#Menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}


#menu-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 100vw;
}

#Header {
  font-size: 30px;
  max-width: 100vw;
  white-space: nowrap;
}

#Footer {
  margin-top: 20px;
  min-height: 50px;
}

#content {
  position: relative;
  min-height: 100vh;
}

#logo {
  font-size: 100px;
  font-family: "Zen Old Mincho";
}

#Contact {
  max-width: 100vw;
  word-wrap: break-word;
}